import { useTheme } from '@mui/material';
import {
  PaymentRequestButtonElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { FC, useEffect, useState } from 'react';

import useAuthContext from '../../hooks/useAuthContext';
import StripeService from '../../services/stripe.service';
import { StartSubscriptionAt } from '../../enums/start-subscription-at.enum';
import moment from 'moment';

type Props = {
  color: 'light' | 'dark';
  callback: (isSuccess: boolean, error?: string) => Promise<void>;
  setIsPayMethodsLoaded: React.Dispatch<React.SetStateAction<boolean>>;
};

export const AppleGooglePay: FC<Props> = ({
  color,
  callback,
  setIsPayMethodsLoaded,
}) => {
  const { stripeCustomerInfo } = useAuthContext();
  const stripe = useStripe();
  const elements = useElements();
  const [paymentRequest, setPaymentRequest] = useState<any>();

  useEffect(() => {
    if (!stripe || !elements) {
      return;
    }

    const pr = stripe.paymentRequest({
      country: 'PL',
      currency: 'eur',
      total: {
        label: stripeCustomerInfo.selectedPlan.title,
        amount: parseFloat(stripeCustomerInfo.selectedPlan.price) * 100,
      },
    });

    pr.canMakePayment().then((result) => {
      if (result) {
        setPaymentRequest(pr);
      }
    });

    pr.on('paymentmethod', async (e) => {
      try {
        const returnUrl = new URL(`${window.location.origin}/payment-success`);

        const { data } = await StripeService.subscribe({
          paymentMethod: e.paymentMethod.id,
          planId: Number(stripeCustomerInfo.selectedPlan.id),
          startSubscriptionAt:
            stripeCustomerInfo.planStart === StartSubscriptionAt.Today
              ? moment().add('month', 1).unix()
              : moment().endOf('month').add('minute', 1).unix(),
          returnUrl: returnUrl.toString(),
        });
        e.complete('success');

        if (data?.redirectUrl) {
          window.location.replace(data.redirectUrl);
        } else {
          callback(true);
        }
      } catch (e: any) {
        e.complete('fail');
        callback(false, e.message);
      }
    });
  }, [stripe, elements]);

  return (
    paymentRequest && (
      <PaymentRequestButtonElement
        onReady={() => setIsPayMethodsLoaded(true)}
        options={{
          paymentRequest: paymentRequest,
          style: {
            paymentRequestButton: {
              theme: color,
            },
          },
        }}
      />
    )
  );
};
