import { Box, Button, Divider, Typography, useTheme } from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';
import { Appearance, loadStripe, Stripe } from '@stripe/stripe-js';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GeneralFuctionType } from '../../@types/props.types';
import useAuthContext from '../../hooks/useAuthContext';
import { useIsDesktop } from '../../hooks/useIsDesktop';
import StripeService from '../../services/stripe.service';
import userService from '../../services/user.service';
import {
  fetchStarted,
  resultLoaded,
  setStripeData,
  userUpdated,
} from '../../store/slices/api.slice';
import { useDispatch } from '../../store/store';
import { getCurrentLanguageLocale } from '../../utils/localizations';
import BlockHeader from '../BlockHeader';
import LoadingScreen from '../LoadingScreen';
import PaymentForm from '../payment';
import { useTranslation } from 'react-i18next';
import { PlanRecurrence } from '../../enums/plan-recurrence.enum';
import { AppleGooglePay } from '../payment/AppleGooglePay';
import { EColorModes } from '../../App';
import useButtonTheme from '../../hooks/useTheme';
import { Loop } from '../loop/Loop';

const SignupFour = ({
  handleActiveSectionChange,
}: {
  handleActiveSectionChange: GeneralFuctionType;
}) => {
  const dispatch = useDispatch();
  const isDesktop = useIsDesktop();
  const theme = useTheme();
  const { t, i18n } = useTranslation('login');
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { stripeCustomerInfo, setStripeInfo } = useAuthContext();
  const [errorMessage, setErrorMessage] = useState('');
  const appearance = {
    theme: 'stripe',
  } as Appearance;
  const [stripe, setStripe] = useState<Stripe | null>(null);
  const [isPayMethodsLoaded, setIsPayMethodsLoaded] = useState(false);
  const buttonColor = useButtonTheme();
  const paymentStripeBtn = useRef<HTMLDivElement | null>(null);
  const btnToCopyClassesRef = useRef<HTMLButtonElement | null>(null);
  const isPaymentFormOpened = useRef(false);
  const accordionRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    btnToCopyClassesRef.current?.classList.forEach((className) => {
      paymentStripeBtn.current?.classList.add(className);
    });
  }, [isDesktop, isPayMethodsLoaded]);

  useEffect(() => {
    let isActive = true;

    if (isActive) {
      setIsLoading(true);
      const locale: any = getCurrentLanguageLocale(i18n.language);

      loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '', {
        locale,
      })
        .then((stripeObject) => setStripe(stripeObject))
        .then(() => setIsLoading(false))
        .catch((error) => console.log(error));
    }

    return () => {
      isActive = false;
    };
  }, [i18n.language]);

  useEffect(() => {
    dispatch(fetchStarted());
    if (!stripeCustomerInfo) {
      StripeService.setupIntents()
        .then((resp) => {
          dispatch(setStripeData(resp.data));
          setStripeInfo({
            ...stripeCustomerInfo,
            ...resp.data,
          });
          handleActiveSectionChange(4);
        })
        .catch((e) => {
          console.error(e);
        });
    }
    dispatch(resultLoaded());
  }, []);

  const options = {
    // passing the client secret obtained in step 3
    clientSecret: stripeCustomerInfo.clientSecret,
    // Fully customizable with appearance API.
    appearance,
    layout: {
      type: 'tabs',
      defaultCollapsed: false,
    },
  };

  const childRef = useRef<HTMLFormElement>(null);

  const handleSubmit = () => {
    setErrorMessage('');
    setIsLoading(true);
    dispatch(fetchStarted());
    childRef?.current?.callSubmit();
  };

  const handlePaymentCallback = async (isSuccess: boolean, error?: string) => {
    dispatch(resultLoaded());
    if (!isSuccess) {
      setIsLoading(false);
      return setErrorMessage(error as string);
    }
    const resp = await userService.me();
    dispatch(userUpdated(resp.data));
    setIsLoading(false);
    navigate('/payment-success');
  };

  const accordionHandler = () => {
    if (accordionRef.current) {
      const isOpen = isPaymentFormOpened.current;

      if (isOpen) {
        accordionRef.current.style.height = '0';
      } else {
        const scrollHeight = accordionRef.current.scrollHeight;
        accordionRef.current.style.height = `${scrollHeight}px`;
      }

      isPaymentFormOpened.current = !isOpen;
    }
  };

  return (
    <>
      {isDesktop ? (
        <BlockHeader title={t('page', { page: 4 })} />
      ) : (
        <Divider sx={{ color: theme.palette.primary.main, width: '100%' }}>
          {t('page', { page: 4 })}
        </Divider>
      )}
      <Box
        sx={{ textAlign: 'center', marginBottom: '24px', marginTop: '24px' }}
      >
        <Typography variant={'h1'}>{t('signup.stepFourTitle')}</Typography>
        <Typography variant={'h3'}>
          {t('signup.stepFourDescription')}
        </Typography>
      </Box>
      <Box
        display={'flex'}
        sx={{
          padding: isDesktop ? '24px' : '16px',
          backgroundColor: theme.palette.customGrey.dark,
          borderRadius: '12px',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: isDesktop ? '24px' : '16px',
          gap: '16px',
        }}
      >
        <Typography variant="body2" flex={1}>
          {t('plan.selectedPlan', {
            plan:
              stripeCustomerInfo.selectedPlan.recurrence ===
              PlanRecurrence.Yearly
                ? t('plan.annual')
                : t('plan.monthly'),
          })}
        </Typography>

        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
            {`${stripeCustomerInfo.selectedPlan.price}€`}
          </Typography>
          {stripeCustomerInfo?.selectedPlan?.metadata?.trialPeriodDays && (
            <Typography variant="caption" fontSize="10px" textAlign="center">
              ({t('plan.afterTrial')})
            </Typography>
          )}
        </Box>
      </Box>
      <Box
        width={'100%'}
        display={'flex'}
        flexDirection={'column'}
        gap={'10px'}
        sx={{ margin: '0 0 10px 0', fontWeight: '500' }}
      >
        <Box
          display={'flex'}
          flexDirection={isDesktop ? 'row' : 'column'}
          width={'100%'}
          sx={{
            margin: '0 !important',
            '.StripeElement': {
              width: '100%',
              transform: isDesktop ? 'scale(1.75)' : 'scale(1.3)',
            },
            backgroundColor:
              buttonColor !== EColorModes.dark
                ? 'white !important'
                : 'black !important',
            overflow: 'hidden',
          }}
          ref={paymentStripeBtn}
        >
          {!isPayMethodsLoaded && (
            <Loop isDarkTheme={buttonColor !== EColorModes.dark} />
          )}
          <Box display={isPayMethodsLoaded ? 'flex' : 'none'} width={'100%'}>
            <Elements stripe={stripe} options={options}>
              <AppleGooglePay
                color={buttonColor}
                callback={handlePaymentCallback}
                setIsPayMethodsLoaded={setIsPayMethodsLoaded}
              />
            </Elements>
          </Box>
        </Box>
        <Button
          fullWidth
          sx={{
            color: 'white !important',
          }}
          ref={btnToCopyClassesRef}
          onClick={() => accordionHandler()}
        >
          {t('payment.card')}
        </Button>
      </Box>
      <Box
        sx={{
          overflow: 'hidden',
          width: '100%',
          height: '0px',
          transition: 'all ease 1.25s',
        }}
        ref={accordionRef}
      >
        <Elements stripe={stripe} options={options}>
          <PaymentForm callback={handlePaymentCallback} ref={childRef} />
        </Elements>

        <Typography variant="caption">
          {t('signup.stepFourCardDescription')}
        </Typography>
        {errorMessage.length > 0 && (
          <Typography sx={{ color: 'primary.main', textAlign: 'center' }}>
            {errorMessage}
          </Typography>
        )}
        <Button
          fullWidth
          data-wait={t('signup.pleaseWaitButtonText')}
          sx={{ marginTop: '10px' }}
          onClick={handleSubmit}
          ref={btnToCopyClassesRef}
        >
          {t('signup.createSubscriptionButtonText')}
        </Button>
      </Box>

      {isLoading && !isPayMethodsLoaded && <LoadingScreen />}
    </>
  );
};

export default SignupFour;
