import { EColorModes } from '../App';

const useButtonTheme = () => {
  const themeColor = localStorage.getItem('colorMode');

  const buttonColor =
    themeColor && themeColor === EColorModes.light
      ? EColorModes.dark
      : EColorModes.light;

  return buttonColor;
};

export default useButtonTheme;
