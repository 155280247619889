import { Box, styled } from '@mui/material';

export const LoopWrapper = styled(Box)<{ isDarkTheme: boolean }>`
  svg {
    animation: spin 2s linear infinite;
    transform-origin: center center;
    @keyframes spin {
      from {
        transform: rotate(0deg) scale(1.5);
      }
      to {
        transform: rotate(360deg) scale(1.5);
      }
    }
  }
  path {
    fill: ${({ theme, isDarkTheme }) => (isDarkTheme ? '#000' : '#fff')};
  }
`;
