import AutorenewIcon from '@mui/icons-material/Autorenew';
import { LoopWrapper } from './components';
import { FC } from 'react';

type LoopProps = {
  isDarkTheme: boolean;
};

export const Loop: FC<LoopProps> = ({ isDarkTheme }) => {
  return (
    <LoopWrapper isDarkTheme={isDarkTheme}>
      <AutorenewIcon />
    </LoopWrapper>
  );
};
